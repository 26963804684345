import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import Nav from '@components/common/Nav';
import Footer from '@components/sections/Footer';
import Layout from '../../layouts';
import Doc from '@layouts/docs';

const Politic: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BubaJobs | Portal de empleo en Argentina" />
        <title>BubaJobs | Portal de empleo en Argentina</title>
        <link rel="canonical" href="http://bubajobs.com.ar/" />
      </Helmet>
      <Nav activeSection={'none'} />
      <Doc>
        <div className="wrapper-doc">
          <div className="principal-text">
            <h1>POLÍTICAS DE PRIVACIDAD</h1>
            <p>
              BubaJobs, como parte normal de los Servicios, recoge y accede a la
              información sobre nuestros usuarios y visitantes, el contenido que
              estos publican, su comportamiento dentro de la app y servicios
              para utilizarlos en la mejora del servicio y la experiencia de
              usuario. BubaJobs no comparte dicha información con terceros sin
              el consentimiento del usuario, salvo en los casos estipulados por
              la ley de cada país o jurisdicción donde se utilice.
            </p>
            <p>
              Estas Políticas de Privacidad (las “<u>Políticas de Privacidad</u>
              ”) describen la información que BubaJobs recoge sobre sus usuarios
              y lo que puede hacerse con dicha información. Al registrarse a la
              Plataforma BubaJobs, el usuario y/o el visitante presta su
              consentimiento para que se utilice su información personal de
              acuerdo a las políticas que se describen a continuación.
            </p>
            <p>
              La privacidad de la información de los Usuarios es muy importante
              para BubaJobs. Es por esa razón que se toman las precauciones y
              recaudos para resguardar su información, utilizando los mecanismos
              de seguridad informática de protección de la información más
              completos y eficaces.
            </p>
            <p>
              Este documento es parte integrante de los Términos y Condiciones
              de BubaJobs, Todos los términos utilizados en mayúsculas que no
              sean expresamente definidos tendrán el significado que a ellos se
              los otorga en los Términos y Condiciones. Mediante la aceptación
              de los Términos y Condiciones en el momento de la registración el
              usuario acepta las políticas aquí contenidas.
            </p>
          </div>
          <div className="secondary-text">
            <h2>
              1. Derechos de acceso, cancelación y rectificación de la
              información personal.
            </h2>
            <p>
              Los usuarios tienen derechos reconocidos y podrán ejercitar los
              derechos de acceder, cancelar y actualizar su Información
              Personal, incluyendo su dirección de e-mail y datos de tarjeta de
              crédito y cuenta bancaria, así como a oponerse al tratamiento de
              la misma y a ser informado de las cesiones llevadas a cabo, todo
              ello de conformidad a lo dispuesto en la normativa aplicable.
              Conforme se establece en el artículo 14, inciso 3 de la Ley Nº
              25.326, el titular de los datos personales, previa acreditación de
              su identidad, tiene la facultad de ejercer el derecho de acceso a
              los mismos, dentro de los 10 (diez) días corridos desde la
              acreditación de su identidad, en forma gratuita a intervalos no
              inferiores a seis meses, salvo que se acredite un interés legítimo
              al efecto. Para la rectificación y supresión de los datos
              personales, el titular de los mismos podrá ejercer tales derechos
              dentro de los 5 (cinco) días hábiles de acreditada su identidad.
            </p>
            <p>
              Los usuarios garantizan y responden, en cualquier caso, de la
              veracidad, exactitud, vigencia y autenticidad de la Información
              Personal facilitada, y se comprometen a mantenerla debidamente
              actualizada.
            </p>
            <p>
              Una vez registrado dentro de BubaJobs, el usuario podrá revisar y
              cambiar la información que nos ha enviado durante el proceso de
              registración incluyendo:
            </p>
            <p>
              - El Apodo y dirección de correo electrónico. Sin perjuicio de los
              cambios que realice, BubaJobs conservará la Información Personal
              anterior por motivos de seguridad y control del fraude;
            </p>
            <p>
              - La información de la registración como: compañía, domicilio,
              ciudad, región, código postal, número principal de teléfono,
              número secundario de teléfono, número de fax, correo electrónico,
              etc.
            </p>
            <p>- La clave.</p>
            <p>
              En determinados casos, mantendremos en nuestros archivos
              Información Personal que nos hayas pedido que retiremos, y ello
              con la finalidad de resolver disputas o reclamaciones, detectar
              problemas o incidencias y solucionarlos, y dar cumplimiento a lo
              dispuesto en los Términos y Condiciones por un período de tiempo
              determinado por la ley. En cualquier caso, la Información Personal
              de un usuario no será inmediatamente retirada de nuestros archivos
              por motivos legales y técnicos, incluyendo sistemas de soportes de
              seguridad. Por tanto, no se debe esperar que toda la Información
              Personal sea definitivamente borrada de nuestras bases de datos.
            </p>
            <p>
              Los Usuarios deben actualizar su Información Personal
              periódicamente conforme vaya cambiando para que los Usuarios
              puedan utilizar de forma correcta los Servicios BubaJobs. BubaJobs
              no se responsabiliza por cualquier inconveniente o defecto en la
              prestación de los Servicios BubaJobs ocasionados por la falta de
              actualización de la Información Personal de los Usuarios. Para
              hacer cualquier modificación en la información suministrada en el
              momento de la Registración, se debe ingresar en la sección de
              Configuración de la cuenta del Usuario.
            </p>
            <p>
              BubaJobs podrá modificar en cualquier momento los términos y
              condiciones de estas Políticas de Privacidad y confidencialidad
              y/o las prácticas de envío de e-mails, mensajes y otro tipo de
              notificaciones. Si decidimos introducir algún cambio material a
              nuestras Políticas de Privacidad, te notificaremos de acuerdo a lo
              establecido en la Cláusula Veinte de los Términos y Condiciones
              Generales.
            </p>
            <p>
              Si hacemos cambios materiales en la forma que tu Información
              Personal es administrada te notificaremos a través de la app o por
              email para que puedas tomar una decisión informada respecto si
              aceptas o no que tu Información Personal sea utilizada de esa
              forma. Si no aceptas esos términos, en ese caso quedará disuelto
              el vínculo contractual y tu Información Personal no será usada de
              otra forma que la que fue informada al momento de recabarse.
            </p>
            <p>
              Para mayor información sobre la confidencialidad de tu Información
              Personal, contáctanos por correo electrónico o postal.
            </p>
            <p>
              Se informa que la Dirección Nacional de Protección de Datos
              Personales, Órgano de Control de la Ley Nº 25.326, tiene la
              atribución de atender las denuncias y reclamos que se interpongan
              con relación al incumplimiento de las normas sobre protección de
              datos personales.
            </p>
            <p>
              Por correo ordinario a: calle 3 de febrero 2881, Piso 3° “B” de la
              Ciudad Autónoma de Buenos Aires, República Argentina.
            </p>
            <p>Por favor contáctate con nosotros.</p>
          </div>
          <div className="secondary-text">
            <h2>2. La información que recabamos.</h2>
            <p>
              Lo primero que se debe hacer para utilizar los Servicios BubaJobs
              es registrarse, suministrando ciertos datos personales (“
              <u>Información Personal</u>”) completos y exactos. Podremos
              solicitar, recabar y almacenar la siguiente Información Personal:
              apodo o seudónimo para operar en la Plataforma BubaJobs, nombre,
              número de documento o identificación válida, información física de
              contacto (como número de teléfono domicilio, dirección de e-mail,
              etc.) además de la información relacionada a tu historial laboral
              y curriculum vitae. BubaJobs podrá confirmar los datos personales
              suministrados acudiendo a entidades públicas, compañías
              especializadas o centrales de riesgo, para lo cual el usuario
              autoriza a BubaJobs de forma expresa mediante la aceptación de la
              presente. La información que BubaJobs obtenga de estas entidades
              será tratada en forma confidencial.
            </p>
            <p>
              En algunos casos puede que te pidamos informar tu tarjeta de
              crédito o cuenta bancaria para realizar pagos o cobros. Dicha
              información no se almacena en las plataformas de BubaJobs y si en
              los medios de pago correspondientes como paypal, mercado pago y
              toda otra plataforma de pago virtual asociada a BubaJobs. Toda la
              información que almacenamos la mantenemos resguardada a través de
              diferentes políticas según los últimos estándares de seguridad que
              garantizan el resguardo de tu información
            </p>
            <p>
              El Usuario que se registre dentro de la Plataforma BubaJobs a
              través de su cuenta personal en una red social (“Cuenta
              Personal”), o de cualquier modo acceda a BubaJobs a través de su
              Cuenta Personal, consiente expresamente que BubaJobs:
            </p>
            <p>
              - Tenga acceso, en cualquier momento, a la totalidad de la
              información contenida en su Cuenta Personal, incluyendo en
              particular, pero sin limitación, a su Información Personal,
              información sobre sus intereses, gustos, contactos y cualquier
              otro contenido alojado en su Cuenta Personal.
            </p>
            <p>
              - Incluya en dicha Cuenta Personal, mensajes, fotografías, videos
              y cualquier otro tipo de contenido.
            </p>
            <p>
              - Envíe al correo electrónico del Usuario vinculado a la Cuenta
              Personal, información o mensajes con la finalidad descripta en la
              sección “Uso que hacemos de la Información” abajo.
            </p>
            <p>
              - Utilice sus patrones de conducta dentro de la Plataforma
              BUBAJOBS y en internet a los fines de utilizar dicha información
              para prestar los servicios y mejorar la experiencia del usuario
              dentro de BubaJobs.
            </p>
            <p>
              BubaJobs recoge y almacena automáticamente cierta información
              sobre la actividad de los usuarios y visitantes dentro de la
              Plataforma BubaJobs. Tal información puede incluir la URL de la
              que provienen (estén o no en nuestro sitio web), a qué URL acceden
              seguidamente (estén o no en nuestro sitio web), qué navegador
              están usando, y sus direcciones IP y el operador de telefonía
              móvil con el cual operan. También las páginas visitadas, las
              búsquedas realizadas, las publicaciones, compras o ventas,
              calificaciones y réplicas ingresadas, mensajes en los foros, entre
              otra información podrá ser almacenada y retenida, para lo cual el
              Usuario otorga a BubaJobs expresa autorización.
            </p>
            <p>
              BubaJobs accede a las listas de contactos de los dispositivos
              móviles utilizados por los usuarios para proveer los Servicios
              BubaJobs. Esa información la utilizará únicamente para localizar y
              señalar números de teléfonos celulares y/o correos electrónicos de
              otros usuarios. A su vez, los usuarios tienen autorización para
              compartir dicha información con BubaJobs.
            </p>
            <p>
              Si los Usuarios envían a BubaJobs correspondencia, sean correos
              electrónicos o cartas, o si otros Usuarios o terceros nos envían
              correspondencia sobre las actividades o sobre los mensajes de
              otros Usuarios dentro de la Plataforma BUBAJOBS, BubaJobs podrá
              recoger y almacenar tal Información Personal, incluyendo
              direcciones de e-mail.
            </p>
            <p>
              Además BubaJobs accede a toda la información que se utilice dentro
              de los servicios o de la app, como el resultado de las encuestas,
              notificaciones, opiniones sobre comercios, mensajes entre
              Usuarios, publicidades, fotografías, entre otros.
            </p>
          </div>
          <div className="secondary-text">
            <h2>3. Uso que hacemos de la información.</h2>
            <p>
              Para suministrar un excelente servicio y para que los usuarios
              puedan utilizar los Servicios BubaJobs en forma ágil y segura,
              BubaJobs requiere acceder a cierta información de carácter
              personal. La recolección de información nos permite ofrecer a los
              usuarios servicios y funcionalidades que se adecuen mejor a sus
              necesidades y personalizar los Servicios BubaJobs para hacer que
              sus experiencias con la Plataforma BubaJobs sean lo más cómodas y
              sencillas posible. La Información Personal que recabamos tiene las
              siguientes finalidades:
            </p>
            <p>
              - Ayudar a los Usuarios a contactarse entre si y notificarte
              acerca de los servicios que ofrecemos y/o toda otra comunicación
              que requerida la Plataforma BubaJobs, como tu confirmación de
              email, información sobre cambios de términos y condiciones, etc.
            </p>
            <p>
              - Desarrollar estudios internos sobre los intereses,
              comportamientos y demografía de los usuarios para comprender mejor
              sus necesidades e intereses y ofrecer mejores servicios o
              proveerles información relacionada.
            </p>
            <p>
              - Mejorar nuestras iniciativas comerciales y promocionales y
              analizar las páginas visitadas las búsquedas realizadas por los
              usuarios, para mejorar nuestra oferta de contenidos y artículos,
              personalizar dichos contenidos, su presentación y servicios.
            </p>
            <p>
              - Enviar información o mensajes por e-mail sobre nuevos servicios,
              mostrar publicidad o promociones, banners, de interés para
              nuestros usuarios, noticias sobre la Plataforma BubaJobs y/o los
              servicios ofrecidos Terceros Proveedores con los cuales cada uno
              de los Usuarios contrate, además de la información expresamente
              autorizada en la sección de Configuración. Si el usuario lo
              prefiere, puede solicitar que lo excluyan de las listas para el
              envío de información promocional o publicitaria. Por favor aprende
              a hacerlo la sección “Cambio de preferencias de e-mail”.
            </p>
            <p>
              - En algunos casos en particular y caso que lo desees, BubaJobs
              podrá suministrar a otros Usuarios alguno de tus datos personales,
              esta información se compartirá bajo tu consentimiento y deberás
              aceptar para compartirla. En caso de no hacerlo es probable que no
              puedas acceder a alguno de los servicios ofrecidos por ese otro
              Usuario.
            </p>
            <p>
              - Enviar información o avisos por mensajes cortos de texto (SMS) u
              otras tecnologías de notificaciones al teléfono celular
              suministrado por el usuario a la Plataforma BubaJobs.
            </p>
            <p>
              - Si bien tus datos no se comparten abiertamente, es posible que
              para prestar algunos servicios de BubaJobs debamos compartir parte
              de tu información Personal (incluyendo en algunos casos dirección
              de e-mail) con proveedores de servicios de BubaJobs con quienes
              BubaJobs tenga una relación de colaboración o alianza, que
              contribuyan a mejorar o facilitar los Servicios BubaJobs, como
              (sin limitarse a) en los rubros de transporte, medios de pago,
              seguros o intermediarios en la gestión de pagos, call centers o
              programas de fidelidad, tarjetas, entre otros. BubaJobs velará
              porque se cumplan ciertos estándares, mediante la firma de
              acuerdos o convenios cuyo objeto sea la privacidad de los datos
              personales de nuestros usuarios. En algunos casos, estos
              proveedores de servicios serán quienes recojan información
              directamente del usuario (por ejemplo si les solicitamos que
              realicen encuestas o estudios). En tales casos, podrá recibir una
              notificación acerca de la participación de un proveedor de
              servicios en tales actividades, y quedará a discreción del usuario
              toda la información que quiera brindarle y los usos adicionales
              que los proveedores decidan hacer. En caso de que los usuarios
              faciliten, por propia iniciativa, información adicional a dichos
              prestadores de servicios directamente, tales Prestadores usarán
              esta información conforme a sus propias políticas de privacidad.
              BubaJobs no se hace responsable por el uso indebido de la
              Información Personal del usuario que hagan compañías o sitios de
              Internet que actúen por cuenta propia.
            </p>
            <p>
              Si decidimos revelar o compartir Información Personal con terceros
              que no son proveedores de servicios o empresas aliadas, afiliadas
              o relacionadas con BubaJobs, requeriremos tu consentimiento.
            </p>
            <p>
              Entre los proveedores de servicios que recogen información
              directamente del usuario se encuentra Google Inc., una compañía de
              Delaware cuya oficina principal está en 1600 Amphitheatre Parkway,
              Mountain View (California), CA 94043, Estados Unidos ("Google").
              Mediante el uso de "cookies" (ver apartado sobre "Cookies" más
              adelante), Google obtiene cierta información sobre la actividad de
              los usuarios de BubaJobs y sus direcciones IP, que es directamente
              transmitida y archivada por Google en los servidores de Estados
              Unidos. Google usará esta información por cuenta de BubaJobs con
              el propósito de analizar y confeccionar informes sobre la
              actividad de los usuarios en Internet, con miras a mejorar los
              servicios prestados por nuestro sitio web. Google podrá transmitir
              dicha información a terceros cuando así se lo requiera la
              normativa aplicable, o cuando dichos terceros procesen la
              información por cuenta de Google. El usuario consiente
              expresamente el tratamiento de su información por Google en la
              forma y para los fines aquí indicados.
            </p>
            <p>
              Suministrar la información personal de los usuarios a las
              entidades que intervengan en la resolución de disputas entre los
              mismos, tales como: Compañías de Seguros, Amigables Componedores o
              Tribunales de Arbitraje o tribunales competentes para solucionar
              tales disputas.
            </p>
          </div>
          <div className="secondary-text">
            <h2>4. Confidencialidad de la información.</h2>
            <p>
              <b>
                Una vez registrado en la Plataforma BubaJobs, BubaJobs no
                venderá, alquilará o compartirá la Información Personal excepto
                en las formas establecidas en estas políticas
              </b>
              . Sin perjuicio de ello, el usuario consiente en forma expresa que
              BubaJobs transfiera total o parcialmente la Información Personal a
              cualquiera de las sociedades controladas, controlantes y/o
              vinculadas con BubaJobs, a cualquier título y en el momento, forma
              y condiciones que estime pertinentes. Haremos todo lo que esté a
              nuestro alcance para proteger la privacidad de la información.
              Puede suceder que en virtud de órdenes judiciales, o de
              regulaciones legales, nos veamos compelidos a revelar información
              a las autoridades o terceras partes bajo ciertas circunstancias, o
              bien en casos que terceras partes puedan interceptar o acceder a
              cierta información o transmisiones de datos en cuyo caso BubaJobs
              no responderá por la información que sea revelada.
            </p>
          </div>
          <div className="secondary-text">
            <h2>5. Clave Personal.</h2>
            <p>
              Para acceder a los Servicios BUBAJOBS reservados únicamente para
              los usuarios debidamente registrados, los usuarios dispondrán de
              una clave personal. Con ella podrán a todos los Servicios
              BUBAJOBS. Esta clave debe mantenerse bajo absoluta
              confidencialidad y, en ningún caso, deberán revelarla o
              compartirla con otras personas.
            </p>
            <p>
              El usuario será responsable de todos los actos que tengan lugar
              mediante el uso de su Apodo y Clave, lo que incluye hacerse cargo
              del pago de las tarifas que eventualmente se devenguen o por los
              perjuicios que puedan sufrir otros usuarios por tal motivo. Si por
              cualquier razón un usuario creyera que alguien puede conocer su
              clave, deberá modificarla ingresando desde el menú de navegación
              en Mi cuenta {'>'} Configuración {'>'} Datos personales o donde se
              indique dentro de la plataforma.
            </p>
            <p>
              BubaJobs, a los efectos de resguardar su seguridad, no tiene
              prácticas de solicitar ningún tipo de información personal, ni
              claves por e-mail o teléfono, sólo lo hará a través de la
              Plataforma BubaJobs.
            </p>
            <p>
              Si recibe un llamado telefónico o e-mail, solicitando información
              personal, no lo responda ni ingrese datos personales ni claves.
            </p>
          </div>
          <div className="secondary-text">
            <h2>6. El uso de la información por otros Usuarios.</h2>
            <p>
              Para facilitar la interacción entre todos los miembros y usuarios
              de la Plataforma BubaJobs, la misma permite un acceso limitado a
              ciertos datos de contacto del resto de usuarios, tales como Nombre
              de usuario (Alias).
            </p>
            <p>
              Los usuarios sólo podrán utilizar la Información Personal de otros
              usuarios obtenida en el sitio para: (a) comunicaciones
              relacionadas con BubaJobs que no constituyan comunicaciones
              comerciales no solicitadas, (b) utilizar servicios ofrecidos por
              la Plataforma BubaJobs (por ejemplo: comunicarse por chat con
              otros usuarios, encuestas, etc), y (c) cualquier otra finalidad a
              la que el usuario correspondiente consienta expresamente una vez
              le hayan sido comunicadas previamente la información legalmente
              requerida.
            </p>
            <p>
              Bajo ninguna circunstancia, deberás comunicar Información Personal
              o dirección de correo electrónico de otro usuario a ningún tercero
              sin nuestro consentimiento y el del usuario afectado. No se debe
              agregar a la agenda de direcciones de correo electrónico (física o
              electrónica) los datos de ningún usuario de la Plataforma
              BUBAJOBS, sin que medie el consentimiento expreso de tal usuario.
            </p>
          </div>
          <div className="secondary-text">
            <h2>7. Cookies.</h2>
            <p>
              El usuario y el visitante de la Plataforma BUBAJOBS conoce y
              acepta que BubaJobs podrá utilizar un sistema de seguimiento
              mediante la utilización de cookies (las “Cookies”). Las Cookies
              son pequeños archivos que se instalan en el disco rígido, con una
              duración limitada en el tiempo que ayudan a personalizar los
              servicios. También ofrecemos ciertas funcionalidades que sólo
              están disponibles mediante el empleo de Cookies. Las Cookies se
              utilizan con el fin de conocer los intereses, el comportamiento y
              la demografía de quienes visitan o son usuarios de la Plataforma
              BUBAJOBS y de esa forma, comprender mejor sus necesidades e
              intereses y darles un mejor servicio o proveerle información
              relacionada.{' '}
            </p>
            <p>
              También usaremos la información obtenida por intermedio de las
              Cookies para analizar las páginas navegadas por el visitante o
              usuario, las búsquedas realizadas, mejorar nuestras iniciativas
              comerciales y promocionales, mostrar publicidad o promociones,
              banners de interés, noticias sobre la Plataforma BUBAJOBS y los
              Servicios BUBAJOBS, perfeccionar nuestra oferta de contenidos y
              servicios, personalizar dichos contenidos, presentación y
              servicios; también podremos utilizar Cookies para promover y hacer
              cumplir las reglas y seguridad del sitio. BubaJobs podrá agregar
              Cookies en los e-mails que envíe para medir la efectividad de las
              promociones.
            </p>
            <p>
              Utilizamos adicionalmente las Cookies para que el usuario no tenga
              que introducir su clave tan frecuentemente durante una sesión de
              navegación, también para contabilizar y corroborar las
              registraciones, la actividad del usuario y otros conceptos para
              acuerdos comerciales, siempre teniendo como objetivo de la
              instalación de las Cookies, el beneficio del usuario que la
              recibe, y no será usado con otros fines ajenos a BubaJobs, la
              Plataforma BUBAJOBS y los Servicios BUBAJOBS.
            </p>
            <p>
              Se establece que la instalación, permanencia y existencia de las
              Cookies en la computadora del usuario depende de su exclusiva
              voluntad y puede ser eliminada de su computadora cuando el usuario
              así lo desee. Para saber cómo quitar las Cookies del sistema es
              necesario revisar la sección de ayuda (Help) del navegador.
            </p>
            <p>
              Adicionalmente, se pueden encontrar Cookies u otros sistemas
              similares instalados por terceros en ciertas páginas de nuestra
              Plataforma BUBAJOBS. Por ejemplo, al navegar por una página creada
              por un usuario, puede que exista una Cookie emplazada en tal
              página.
            </p>
            <p>
              Se aclara expresamente que estas políticas cubre la utilización de
              Cookies por este sitio y no la utilización de Cookies por parte de
              anunciantes. Nosotros no controlamos el uso de Cookies por
              terceros.
            </p>
          </div>
          <div className="secondary-text">
            <h2>8. Web Beacons.</h2>
            <p>
              Un Web beacon es una imagen electrónica, también llamada
              single-pixel (1 x 1) o pixel transparente, que es colocada en
              código de una página Web. Un Web beacon tiene finalidades
              similares a las Cookies. Adicionalmente un Web beacon es utilizado
              para medir patrones de tráfico de los usuarios de una página a
              otra con el objeto de maximizar como fluye el tráfico a través de
              la Web. El usuario y el visitante del sitio Web de BubaJobs conoce
              y acepta que BubaJobs podrá utilizar un sistema de seguimiento
              mediante la utilización de Web beacons.
            </p>
          </div>
          <div className="secondary-text">
            <h2>9. Spam.</h2>
            <p>
              BubaJobs y sus usuarios no aceptan conductas consideradas
              "spamming", ya sea en opiniones, preguntas, respuestas y/o el
              envío no solicitado de correos electrónicos. Queda absolutamente
              prohibido el envío indiscriminado de mensajes de cualquier
              naturaleza entre los usuarios de la Plataforma BUBAJOBS que no
              esté enmarcado dentro de los Servicios.
            </p>
            <p>
              BubaJobs podrá suspender o inhabilitar definitivamente a aquellos
              usuarios que violen esta política.
            </p>
          </div>
          <div className="secondary-text">
            <h2>
              10. Orden de autoridades competentes - Requerimientos Legales.
            </h2>
            <p>
              BubaJobs coopera con las autoridades competentes y con otros
              terceros para garantizar el cumplimiento de las leyes, por ejemplo
              en materia de protección de derechos de propiedad industrial e
              intelectual, prevención del fraude y otras materias.
            </p>
            <p>
              BubaJobs podrá revelar la Información Personal de sus usuarios
              bajo requerimiento de la autoridades judiciales o gubernamentales
              competentes para efectos de investigaciones conducidas por ellas,
              aunque no exista una orden ni una citación ejecutiva o judicial, o
              por ejemplo (y sin limitación a este supuesto) cuando se trate de
              investigaciones de carácter penal o de fraude o las relacionadas
              con piratería informática o la violación de derechos de autor. En
              tales situaciones, BubaJobs colaborará con las autoridades
              competentes con el fin de salvaguardar la integridad y la
              seguridad de la comunidad y la de sus usuarios.
            </p>
            <p>
              BubaJobs puede (y los usuarios lo autorizan expresamente)
              comunicar cualquier Información Personal sobre sus usuarios con la
              finalidad de cumplir la normativa aplicable y cooperar con las
              autoridades competentes en la medida en que discrecionalmente lo
              entienda necesario y adecuado en relación con cualquier
              investigación de un ilícito o un fraude, infracción de derechos de
              propiedad industrial o intelectual, u otra actividad que sea
              ilegal o que pueda exponer a BubaJobs o a sus usuarios a cualquier
              responsabilidad legal.
            </p>
            <p>
              Además, BubaJobs se reserva el derecho (y el usuario autoriza
              expresamente a ello) de comunicar información sobre sus usuarios a
              otros usuarios, entidades o terceros cuando haya motivos
              suficientes para considerar que la actividad de un usuario sea
              sospechosa de intentar o cometer un delito o intentar perjudicar a
              otras personas. Este derecho será utilizado por BubaJobs a su
              entera discreción cuando lo considere apropiado o necesario para
              mantener la integridad y la seguridad de la comunidad y la de sus
              usuarios, para hacer cumplir los Términos y Condiciones Generales
              y demás Políticas de la Plataforma BUBAJOBS y a efectos de
              cooperar con la ejecución y cumplimiento de la ley. Este derecho
              será ejercido por BubaJobs independientemente que no exista una
              orden judicial o administrativa al efecto.
            </p>
          </div>
          <div className="secondary-text">
            <h2>11. Seguridad. Almacenamiento de la información.</h2>
            <p>
              BubaJobs está obligado a cumplir con toda la normativa aplicable
              en materia de medidas de seguridad aplicables a la Información
              Personal. Adicionalmente, BubaJobs usa los estándares de la
              industria entre materia de protección de la confidencialidad de su
              Información Personal, incluyendo, en otras medidas, cortafuegos
              ("firewalls") y Secure Socket Layers ("SSL"). BubaJobs considera a
              los datos de sus usuarios como un activo que debe ser protegido de
              cualquier pérdida o acceso no autorizado. Empleamos diversas
              técnicas de seguridad para proteger tales datos de accesos no
              autorizados por usuarios de dentro o fuera de nuestra compañía.
              Sin embargo, es necesario tener muy en cuenta que la seguridad
              perfecta no existe en Internet.
            </p>
            <p>
              El usuario de la Plataforma BUBAJOBS y de BubaJobs conoce y acepta
              expresamente que BubaJobs, a su exclusivo criterio, recabe,
              almacene y monitoree el intercambio de mensajes y correos
              electrónicos entre sus usuarios, efectuado dentro de la comunidad
              de la Plataforma BUBAJOBS, con el objetivo de contribuir a la
              seguridad de las relaciones y comunicaciones en la comunidad.
            </p>
            <p>
              Por ello, BubaJobs no se hace responsable por interceptaciones
              ilegales o violación de sus sistemas o bases de datos por parte de
              personas no autorizadas. BubaJobs tampoco se hace responsable por
              la indebida utilización de la información obtenida por esos
              medios.
            </p>
            <p>
              Todos los datos personales de los usuarios serán almacenados en un
              fichero automatizado de datos personales. El usuario al
              registrarse en la Plataforma BUBAJOBS confirma que está informado
              de la residencia de este fichero y autoriza esta transferencia
              internacional de sus datos.
            </p>
          </div>
          <div className="secondary-text">
            <h2>12. Cambio en las preferencias de notificaciones.</h2>
            <p>
              Sin perjuicio de que BubaJobs quiere mantener a sus usuarios
              actualizados en todo momento sobre promociones, novedades,
              cambios, etc. los usuarios pueden seleccionar las notificaciones
              de información promocional que gustarían recibir de BubaJobs y de
              la Plataforma BUBAJOBS.
            </p>
            <p>
              Si no quieres recibir notificaciones, puedes cancelar las mismas
              cambiando tus preferencias de desde la app siguiendo las
              instrucciones que proporcionamos en nuestras comunicaciones o bien
              accediendo a tu cuenta. En esa sección podrás seleccionar las
              preferencias para que sean tenidas en cuenta por BubaJobs en las
              futuras comunicaciones o enviando tus preferencias por correo a la
              dirección postal indicada en estas políticas.
            </p>
          </div>
        </div>
      </Doc>
      <Footer />
    </Layout>
  );
};

export default Politic;
